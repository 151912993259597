
import { defineComponent, PropType, watch, reactive, onMounted } from 'vue'
import { UserProps } from '@/store'
import { useRoute } from 'vue-router'

export type TagType = 'input' | 'textarea'
interface BreadcrumbType {
  path: string
  name: string
}
export default defineComponent({
  name: 'Header',
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    },
    isCollapse: {
      type: Boolean
    },
    tag: {
      type: String as PropType<TagType>,
      default: 'input'
    }
  },
  emits: ['handleCollapse'],
  setup(props, { emit }) {
    let isCollapse = false
    function handleCollapse() {
      isCollapse = !props.isCollapse
      emit('handleCollapse', isCollapse)
    }
    const route = useRoute()

    let routes = reactive<BreadcrumbType[]>([])

    onMounted(() => {
      getRoutesData(route.matched)
    })

    watch(
      () => route.matched,
      () => {
        // console.log(route.matched)
        getRoutesData(route.matched)
      }
    )

    function getRoutesData(matched: any) {
      routes.length = 0
      for (let item of matched) {
        const routeItem: BreadcrumbType = {
          path: item.path,
          name: item.meta.title
        }
        routes.push(routeItem)
      }
    }
    return { handleCollapse, routes }
  }
})
