
import { defineComponent, ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { GlobalDataProps } from '@/store'
import Header from '@/layouts/Header.vue'
import Footer from './Footer.vue'

interface MenuProps {
  index: string
  label: string
  icon?: string
  children?: MenuProps[]
}

const menuData: MenuProps[] = [
  {
    index: '/',
    label: '帮助中心管理',
    icon: 'el-icon-document',
    children: [
      {
        index: '/use-document',
        label: '使用说明管理'
      },
      {
        index: '/problem-document',
        label: '常见问题管理'
      },
      {
        index: '/update-document',
        label: '更新日志管理'
      }
    ]
  }
]

export default defineComponent({
  components: { Header, Footer },
  setup() {
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    const route = useRoute()
    const currentUser = computed(() => store.state.user)
    const isCollapse = ref(false)
    const handleCollapse = (collapse: boolean) => {
      isCollapse.value = collapse
    }
    const handleMenuSelect = (index: any) => {
      // console.log(index, indexPath)
      router.push(index)
    }
    let activePath = '/use-document'
    watchEffect(() => {
      // console.log(route.path)
      activePath = route.path
    })
    return {
      isCollapse,
      menuData,
      currentUser,
      activePath,
      handleCollapse,
      handleMenuSelect
    }
  }
})
